import { getAuth } from "@clerk/remix/ssr.server"
import { LoaderFunction, redirect } from "@remix-run/node"
import { Outlet } from "@remix-run/react"
import { MAINTENANCE_MODE } from "~/utils/env/server"
import { JuneAnalyticsProvider } from "~/utils/hooks/useJuneAnalytics"

export const loader: LoaderFunction = async (args) => {
  if (MAINTENANCE_MODE) {
    if (!args.request.url.endsWith("/maintenance"))
      throw redirect("/maintenance")

    return null
  }

  const { userId } = await getAuth(args)

  if (userId) {
    throw redirect("/")
  }

  return null
}

const Root = (): JSX.Element => {
  return (
    <JuneAnalyticsProvider>
      <Outlet />
    </JuneAnalyticsProvider>
  )
}

export default Root
